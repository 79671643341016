import {Button, FormControl, Input, InputGroup, InputRightElement} from '@chakra-ui/react';
import React, {ChangeEvent, FC, KeyboardEvent, ReactElement, useEffect, useRef, useState} from 'react';

import {useInputFocus} from '@app/store/services/inputFocusSlice.ts';

const InputButton: FC<{
  placeholder: string;
  btnName?: string;
  searchValue: (value: string) => void;
}> = ({placeholder, btnName = 'Valider', searchValue}): ReactElement => {
  const [value, setValue] = useState('');
  const ref = useRef<HTMLInputElement>(null);
  const {needToFocus, setFocused} = useInputFocus();

  const updateValue = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  const onClick = (): void => {
    searchValue(value);
    resetValue();
  };

  const resetValue = (): void => {
    setValue('');
    // @ts-ignore
    ref.current.value = '';
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      searchValue(value);
      resetValue();
    }
  };

  useEffect(() => {
    if (needToFocus) {
      // @ts-ignore
      ref.current.focus();
    }
  }, [needToFocus]);

  return (
    <>
      <FormControl onSubmit={onClick}>
        <InputGroup>
          <Input
            autoFocus
            placeholder={placeholder}
            size="5xl"
            variant="flushed"
            ref={ref}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onChange={e => updateValue(e)}
            onKeyDown={e => handleKeyDown(e)}
          />
          <InputRightElement width="15rem" borderRadius="16px">
            <Button size="4xl" borderRadius="10px" onClick={onClick}>
              {btnName}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </>
  );
};

export default InputButton;
