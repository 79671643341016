import React, {FC, ReactElement, useEffect, useState} from 'react';
import {useOutletContext} from 'react-router-dom';

import OrderInformation from '@app/components/Shop/OrderInformation/OrderInformation';
import Search from '@app/components/Shop/Shipment/Search/Search';
import {useLazySearchOrderQuery} from '@app/store/camalo/orderApi';
import {shopContext} from '@app/types/ContextType';
import {Order} from '@app/types/Order/Information/Order';

const OrderInformationApp: FC = (): ReactElement => {
  const {shop, printerList} = useOutletContext<shopContext>();
  const [triggerOrderSearch, {isLoading, isError}] = useLazySearchOrderQuery();
  const [order, setOrder] = useState<Order>();

  useEffect(() => {
    setOrder(undefined);
  }, [isError]);

  const searchAction = async (data: string): Promise<void> => {
    const response = await triggerOrderSearch({
      shopSlug: shop.slug!,
      query: data,
    }).unwrap();
    setOrder(response);
  };

  return (
    <>
      <Search title={'Commande - information'} placeholder={'Numéro de commande'} searchAction={searchAction} />
      {order && order.id && <OrderInformation order={order} />}
    </>
  );
};

export default OrderInformationApp;
